<template>
	<div>
		<!-- 标题 -->
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="user" />
				<span>{{ user.realName }}({{ user.jobNumber }})</span>
			</div>
		</div>
		<div class="my-md">
			<!-- usePagination开启分页 -->
			<!-- loading绑定引入页面的蒙层加载变量 -->
			<q-container usePagination :loading="loading" useModal>
				<!-- 使用具名插槽 #toolbar -->
				<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
				<template #toolbar>
					<a-row :gutter="[8, 8]">
						<a-col span="3">
							<a-button
								:type="'primary'"
								@click="Editmanual()"
								v-if="isGranted('user_leave_balance_edit')"
							>
								<a-icon type="edit" />
								<span>{{ l('修改额度') }}</span>
							</a-button>
						</a-col>
						<a-col span="3">
							<a-button
								:type="'primary'"
								@click="selectdetails()"
								v-if="isGranted('user_leave_balance_view')"
							>
								<a-icon type="profile" />
								<span>{{ l('查看明细') }}</span>
							</a-button>
						</a-col>
						<a-col span="2"> </a-col>
						<a-col span="6">
							假期类型：
							<a-select
								show-search
								allowClear
								:placeholder="l('请选择')"
								option-filter-prop="children"
								style="width: 70%"
								v-model="AttGroupId"
								@change="SelectRule"
							>
								<a-select-option v-for="item in AttGroupList" :key="item.code">
									{{ item.name }}
								</a-select-option>
							</a-select>
						</a-col>
						<a-col span="10">
							结束日期：
							<a-range-picker @change="timechange" />
						</a-col>
					</a-row>
				</template>
				<!-- 使用具名插槽slot="table" -->
				<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
				<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

				<!-- **************行选择*************** -->
				<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
				<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
				<!-- 多选还需要添加:rowSelection="rowSelection" -->
				<template slot="table" slot-scope="scopeParam">
					<a-table
						ref="table"
						size="small"
						:pagination="false"
						:columns="columns"
						:rowKey="(tableDatas) => tableDatas.id"
						:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
						:dataSource="tableData"
						:customRow="customRow"
						:rowClassName="rowClassName"
					>
					</a-table>
				</template>
				<!-- 使用具名插槽 #page -->
				<template #page>
					<!-- 分页组件只需复制以下代码 -->
					<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
					<a-pagination
						v-model="pageNumber"
						:total="totalItems"
						showSizeChanger
						size="small"
						:defaultPageSize="request.maxResultCount"
						:pageSizeOptions="pageSizeOptions"
						:showTotal="() => showTotal"
						@change="onPageChange"
						@showSizeChange="showPageSizeChange"
					/>
				</template>
			</q-container>
		</div>
	</div>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import { QuotaUpdateRecordServiceProxy } from '../../../../shared/service-proxies';
import UserLeaveBalaceView from './user-leave-balance-tabs/user-leave-balance-tabs';
import ModalHelper from '../../../../shared/helpers/modal/modal-helper';
import moment from 'moment';
import EditUserLeaceBalance from './user-leave-balance-tabs/edit-user-leave-balance/edit-user-leave-balance';

export default {
	name: 'user-leave-balance-view',
	mixins: [ModalComponentBase],
	components: { UserLeaveBalaceView },
	data() {
		return {
			user: undefined,
			holidayType: undefined,
			tabsList: [],
			// 表格
			columns: [
				{
					title: this.l('假期类型'),
					dataIndex: 'holidayType',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'holidayType' },
				},
				{
					title: this.l('开始日期'),
					dataIndex: 'startDate',
					sorter: false,
					width: 110,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'startDate' },
				},
				{
					title: this.l('结束日期'),
					dataIndex: 'endDate',
					sorter: false,
					width: 110,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'endDate' },
				},
				{
					title: this.l('延期日期'),
					dataIndex: 'delayDate',
					align: 'center',
					width: 110,
					ellipsis: true,
					scopedSlots: { customRender: 'delayDate' },
				},
				{
					title: this.l('标准额度'),
					dataIndex: 'normalQuota',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'normalQuota' },
				},
				{
					title: this.l('实际额度'),
					dataIndex: 'realQuota',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'realQuota' },
				},
				{
					title: this.l('手工调整'),
					dataIndex: 'manualUpdate',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'manualUpdate' },
				},
				{
					title: this.l('锁定额度'),
					dataIndex: 'lockingQuota',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'lockingQuota' },
				},
				{
					title: this.l('已用额度'),
					dataIndex: 'usedQuota',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'usedQuota' },
				},
				{
					title: this.l('可用额度'),
					dataIndex: 'usableQuota',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'usableQuota' },
				},
				{
					title: this.l('单位'),
					dataIndex: 'holidayUnit',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'holidayUnit' },
				},
			],

			tableData: [],
			thisDom: undefined,
			startTime: undefined,
			endTime: undefined,
			AttGroupList: [],
			AttGroupId: undefined,
		};
	},
	created() {
		this.fullData();
		this._quotaUpdateRecordServiceProxy = new QuotaUpdateRecordServiceProxy(this.$apiUrl, this.$api);
		this.columns = this.getColumn('假期余额', this.columns);
	},
	async mounted() {
		this._quotaUpdateRecordServiceProxy.getHolidayRuleList().then((res) => {
			this.AttGroupList = res;
		});
		this.getData();
		// if (this.user) {
		//     this.tableData = this.getData();
		// }
	},
	methods: {
		getData() {
			this.loading = true;
			this._quotaUpdateRecordServiceProxy
				.getPagedByUserId(
					this.user.id,
					this.holidayType,
					this.startTime,
					this.endTime,
					undefined,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.tableData.map((item) => {
						item.checked = false;
						item.startDate = item.startDate ? moment(item.startDate).format('YYYY-MM-DD') : ' ';
						item.endDate = item.endDate ? moment(item.endDate).format('YYYY-MM-DD') : ' ';
						item.delayDate = item.delayDate ? moment(item.delayDate).format('YYYY-MM-DD') : ' ';
					});
					this.totalItems = res.totalCount;
				});
		},
		//下拉框选中事件
		SelectRule(value) {
			this.holidayType = value;
			this.request.skipCount = 0;
			this.pageNumber = 1;
			this.getData();
		},

		Editmanual() {
			if (this.selectedRows.length <= 0) {
				abp.message.warn('请选择一条记录');
			} else {
				ModalHelper.create(
					EditUserLeaceBalance,
					{
						limitdto: this.selectedRows[0],
					},
					{ width: '700px' }
				).subscribe(async (res) => {
					if (res) {
						this.getData();
					}
				});
			}
		},

		/**
		 * 日期选择timechange
		 */
		async timechange(date, time) {
			if (date.length > 0) {
				this.startTime = moment(time[0]);
				this.endTime = moment(time[1]);
			} else {
				this.startTime = undefined;
				this.endTime = undefined;
				this.selectedRows = undefined;
			}
			this.request.skipCount = 0;
			this.pageNumber = 1;
			this.getData();
		},
		//查询明细
		selectdetails() {
			if (this.selectedRows.length <= 0) {
				abp.message.warn('请选择一条记录');
			} else {
				ModalHelper.create(
					UserLeaveBalaceView,
					{
						limitdto: this.selectedRows[0],
					},
					{
						width: '900px',
					}
				).subscribe((res) => {});
			}
		},
	},
};
</script>

<style scoped>
.no-have {
	width: 100%;
	height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	color: #8c8c8c;
}

.pagination {
	margin: 10px auto;
	text-align: right;
}
</style>
