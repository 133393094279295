<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row :gutter="[10, 10]">
				<a-col :span="4">
					<a-tree-select
						:placeholder="l('Company')"
						@change="handleChange"
						style="width: 100%"
						tree-default-expand-all
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="companyTreeList"
						v-model="comPanyId"
						allowClear
					/>
				</a-col>
				<a-col :span="4">
					<a-tree-select
						v-model="departmentId"
						allowClear
						style="width: 100%"
						show-search
						:filterTreeNode="searchTree"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="DepTreeData"
						:placeholder="l('Department')"
						@change="search"
						tree-default-expand-all
					>
					</a-tree-select>
				</a-col>
				<a-col :span="4">
					<a-select
						style="width: 200px;margin: 0px 10px"
						allowClear
						v-model="EmpStateCode"
						defaultActiveFirstOption
						@change="search()"
						:placeholder="l('员工状态')"
					>
						<a-select-option v-for="item in dicEmpStateList" :key="item.itemDetailCode">
							{{ item.itemDetailName }}
						</a-select-option>
					</a-select>
				</a-col>
				<a-col :span="5" offset="7" style="text-align: right">
					<a-input-search
						style="width: 200px;"
						name="filterText"
						:placeholder="l('SearchWithThreeDot')"
						@search="search"
						enterButton
						v-model="filterText"
					/>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				@change="sorterChange"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
				:dataSource="data"
			>
				<span slot="jobNumber" slot-scope="text, record">
					<a @click="showUserLeave(record)">{{ record.jobNumber }}</a>
				</span>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import {
	CompanyServiceProxy,
	DepartmentServiceProxy,
	SignCardRecordServiceProxy,
	UserServiceProxy,
} from '../../../shared/service-proxies';
import * as _ from 'lodash';
import moment from 'moment';
import UserLeaveBalanceView from './user-leave-balance-view/user-leave-balance-view';
import Dic from '../../../shared/utils/dic';
import { abpService } from '../../../shared/abp';
import DepTreeUntils from '../../organization/department/dep-tree-untils/dep-tree-untils';
import modalHelper from '@/shared/helpers/modal/modal-helper';

export default {
	name: 'user-leave-balance',
	mixins: [AppComponentBase],
	components: { UserLeaveBalanceView },
	data() {
		return {
			companyTreeList: [],
			comPanyId: undefined,
			departmentId: undefined,
			//部门树
			DepTreeData: [],
			DepTree: [],

			// 用户表格
			columns: [
				{
					title: this.l('JobNumber'),
					dataIndex: 'jobNumber',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'jobNumber' },
				},
				{
					title: this.l('RealName'),
					dataIndex: 'realName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'realName' },
				},
				{
					title: this.l('Company'),
					dataIndex: 'companyName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'companyName' },
				},
				{
					title: this.l('Department'),
					dataIndex: 'departmentName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'departmentName' },
				},
				{
					title: this.l('EmpType'),
					dataIndex: 'employeeType',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'employeeType' },
				},
				{
					title: this.l('HireDate'),
					dataIndex: 'hireDate',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'hireDate' },
				},
			],
			// 用户数据
			data: [],
			thisDom: undefined,
			EmpStateCode: undefined,
			dicEmpStateList: [],
		};
	},
	created() {
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._signCardRecordServiceProxy = new SignCardRecordServiceProxy(this.$apiUrl, this.$api);
		this._UserServiceProxy = new UserServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		this.dicEmpStateList = await Dic.getInstance().getDicAsync('EmpState');
		this.EmpStateCode = '01';
		this.companyTreeInit();
		if (abpService.abp.userInfo.companyId) {
			this.comPanyId = abpService.abp.userInfo.companyId;
			this.handleChange(this.comPanyId);
		}
		this.search();
	},
	methods: {
		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},
		companyTreeInit() {
			this.loading = true;
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
					// if (this.companyTreeList.length > 0) {
					//     this.handleChange(this.companyTreeList[0].key);
					// }
				});
		},

		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},

		//公司下拉框
		handleChange(value) {
			this.comPanyId = value;
			this.pageNumber = 1;
			this.request.skipCount = 0;

			this.departmentId = undefined;

			this.getData();
			this.getDepTreeData();
		},
		deptChange(value) {
			this.pageNumber = 1;
			this.request.skipCount = 0;
			this.departmentId = value;
			this.getData();
		},
		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.DepTreeData = [];
			this._DepartmentServiceProxy
				.getDepTreeData(this.comPanyId)
				.then((res) => {
					this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							this.DepTreeData.push(item);
						}
					});
					this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		showUserLeave(user) {
			modalHelper
				.create(
					UserLeaveBalanceView,
					{
						user,
					},
					{
						width: '1200px',
					}
				)
				.subscribe((res) => {});
		},

		/**
		 * 拉取数据
		 */
		getData() {
			this.loading = true;
			this._signCardRecordServiceProxy
				.getPageUserList(
					this.comPanyId,
					this.departmentId,
					this.EmpStateCode,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((result) => {
					this.data = result.items;
					this.data.map((item) => {
						item.hireDate = item.hireDate ? moment(item.hireDate).format('YYYY-MM-DD') : '';
					});
					this.totalItems = result.totalCount;
				});
		},
	},
};
</script>

<style scoped></style>
