<template>
	<a-spin :spinning="spinning">
		<!-- 标题 -->
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="medicine-box" />
				<span>{{ l('手工调整') }}</span>
			</div>
		</div>
		<div class="my-md">
			<a-row>
				<a-col :span="4">
					<q-label required :label="l('手工调整')"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-input-number v-model="entity.modifyValue" style="width: 100%" placeholder="手工调整" />
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="4">
					<q-label required :label="l('理由')"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-textarea placeholder="理由" v-model="entity.reason" :rows="4" />
				</a-col>
			</a-row>
		</div>
		<!-- 按钮 -->
		<div class="modal-footer">
			<a-button @click="close" type="button">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :type="'primary'" @click="save">
				<a-icon type="save" />
				{{ l('Save') }}
			</a-button>
		</div>
	</a-spin>
</template>

<script>
import ModalComponentBase from '@/shared/component-base/modal-component-base';
import { QuotaUpdateRecordEditDto, QuotaUpdateRecordServiceProxy } from '@/shared/service-proxies';

export default {
	name: 'edit-user-leave-balance',
	mixins: [ModalComponentBase],
	data() {
		return {
			spinning: false,
			id: undefined,
			entity: new QuotaUpdateRecordEditDto(),
			userId: undefined,
			holidayType: undefined,
			limitdto: undefined,
		};
	},
	created() {
		this.fullData();
		this._quotaUpdateRecordServiceProxy = new QuotaUpdateRecordServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {},
	methods: {
		save() {
			if (!this.entity.modifyValue) {
				if (this.entity.modifyValue != 0) {
					return abp.message.warn('请输入修改额度');
				}
				if (this.entity.modifyValue == 0) {
					return abp.message.warn('额度值无异议');
				}
			}
			if (!this.entity.reason) {
				return abp.message.warn('请输入理由');
			}
			this.entity.limitId = this.limitdto.limitId;
			this.entity.userId = this.limitdto.userId;
			this.spinning = true;
			this._quotaUpdateRecordServiceProxy
				.edit(this.entity)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped></style>
