<template>
	<a-config-provider :locale="zh_CN">
		<div>
			<div class="modal-header">
				<div class="modal-title">
					<span>查看明细</span>
				</div>
			</div>
			<!-- usePagination开启分页 -->
			<!-- loading绑定引入页面的蒙层加载变量 -->
			<q-container usePagination :loading="loading" useModal>
				<!-- 使用具名插槽 #toolbar -->
				<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
				<template #toolbar>
					<a-row>
						<a-col :span="8">
							<a-row>
								<a-col :span="4">
									<q-label :label="l('日期')"> </q-label>
								</a-col>
								<a-col :span="20">
									<a-range-picker
										@change="onTimeChange"
										class="q-w-f"
										:placeholder="['开始日期', '结束日期']"
									/>
								</a-col>
							</a-row>
						</a-col>
						<a-col :span="6" offset="1">
							<a-input-search
								name="filterText"
								style="width: 100%"
								:placeholder="l('SearchWithThreeDot')"
								@search="search"
								enterButton
								v-model="filterText"
							/>
						</a-col>
					</a-row>
				</template>
				<!-- 使用具名插槽slot="table" -->
				<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
				<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

				<!-- **************行选择*************** -->
				<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
				<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
				<!-- 多选还需要添加:rowSelection="rowSelection" -->
				<template slot="table" slot-scope="scopeParam">
					<a-table
						ref="table"
						size="small"
						:pagination="false"
						:columns="columns"
						:rowKey="(tableDatas) => tableDatas.id"
						:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
						:dataSource="tableData"
					>
					</a-table>
				</template>
				<!-- 使用具名插槽 #page -->
				<template #page>
					<!-- 分页组件只需复制以下代码 -->
					<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
					<a-pagination
						v-model="pageNumber"
						:total="totalItems"
						showSizeChanger
						size="small"
						:defaultPageSize="request.maxResultCount"
						:pageSizeOptions="pageSizeOptions"
						:showTotal="() => showTotal"
						@change="onPageChange"
						@showSizeChange="showPageSizeChange"
					/>
				</template>
			</q-container>
		</div>
	</a-config-provider>
</template>

<script>
import * as _ from 'lodash';
import EditUserLeaceBalance from './edit-user-leave-balance/edit-user-leave-balance';
import ModalHelper from '@/shared/helpers/modal/modal-helper';
import { LeaveRecordServiceProxy, QuotaUpdateRecordServiceProxy } from '@/shared/service-proxies';
import moment from 'moment';
import ModalComponentBase from '@/shared/component-base/modal-component-base';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';

export default {
	name: 'user-leave-balance-tabs',
	mixins: [ModalComponentBase],
	components: { EditUserLeaceBalance },

	data() {
		return {
			zh_CN,

			// 用户表格
			columns: [
				{
					title: this.l('QuotaUpdateTime'),
					dataIndex: 'updateTime',
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'updateTime' },
				},
				{
					title: this.l('QuotaUpdateUser'),
					dataIndex: 'lastUpdateUserName',
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'lastUpdateUserName' },
				},
				{
					title: this.l('QuotaUpdateValue'),
					dataIndex: 'modifyValue',
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'modifyValue' },
				},
				{
					title: this.l('QuotaUpdateReason'),
					dataIndex: 'reason',
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'reason' },
				},
			],
			tableData: [],
			selectRows: [],
			holidayLimit: undefined,
			startTime: undefined,
			endTime: undefined,
			limitdto: undefined,
			filterText: undefined,
		};
	},
	created() {
		this.fullData();
		this._quotaUpdateRecordServiceProxy = new QuotaUpdateRecordServiceProxy(this.$apiUrl, this.$api);
		this._leaveRecordServiceProxy = new LeaveRecordServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		this.getData();
	},
	methods: {
		getData() {
			this.loading = true;
			this._quotaUpdateRecordServiceProxy
				.getPaged(
					this.limitdto.limitId,
					this.limitdto.userId,
					this.startTime,
					this.endTime,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.tableData.map((item) => {
						item.updateTime = moment(item.updateTime).format('YYYY-MM-DD hh:mm');
					});
					this.totalItems = res.totalCount;
				});
		},
		/**
		 * 获取假期余额
		 */
		getHolidayLimit() {
			return new Promise((resolve, reject) => {
				this.spinning = true;
				this._leaveRecordServiceProxy
					.getHolidayLimitByUserId(this.userId, this.holidayType)
					.finally(() => {
						this.spinning = false;
					})
					.then((res) => {
						this.holidayLimit = res;
						resolve(res);
					});
			});
		},
		/**
		 * 编辑
		 * @constructor
		 */
		Edit() {
			ModalHelper.create(
				EditUserLeaceBalance,
				{
					userId: this.userId,
					holidayType: this.holidayType,
				},
				{ width: '700px' }
			).subscribe(async (res) => {
				if (res) {
					await this.getHolidayLimit(this.holidayType);
					this.getData();
				}
			});
		},

		/**
		 * 日期选择timechange
		 */
		async timechange(date, time) {
			if (date.length > 0) {
				this.startTime = moment(time[0]);
				this.endTime = moment(time[1]);
				//await this.getCycle();
			} else {
				this.startTime = undefined;
				this.endTime = undefined;
			}
			this.getData();
		},
		onTimeChange(record) {
			if (record.length > 0) {
				this.startTime = moment(moment(record[0]).format('YYYY-MM-DD'));
				this.endTime = moment(moment(record[1]).format('YYYY-MM-DD'));
				this.onChange(1, undefined);
				this.showSizeChange(undefined, this.request.maxResultCount);
			} else {
				this.startTime = undefined;
				this.endTime = undefined;
				this.onChange(1, undefined);
				this.showSizeChange(undefined, this.request.maxResultCount);
			}
			this.getData();
		},
	},
};
</script>

<style scoped>
.pagination {
	margin: 10px auto;
	text-align: right;
}
</style>
